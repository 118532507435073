import { render, staticRenderFns } from "./bigdata.vue?vue&type=template&id=3f21e355&scoped=true&"
import script from "./bigdata.vue?vue&type=script&lang=js&"
export * from "./bigdata.vue?vue&type=script&lang=js&"
import style0 from "./bigdata.vue?vue&type=style&index=0&id=3f21e355&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f21e355",
  null
  
)

export default component.exports