<template>
<div class="main" :style="conheight">
  <div class="title">种子追溯码大数据</div>
    <el-button type="primary" class="full" @click="isScreenFull">全屏展示</el-button>
    <div id="mapDiv" :style="conheight" class="mapDiv" ref="mapDiv"></div>
    
     </div>
  
</template>

<script>
import screenfull from "screenfull";
import tiandi from "../../assets/js/maptiandi"
export default {
    data(){
        return{
          data:{},
　　　　　　conheight:{
                height:''
            },
            isFullscreen: false
        }
　　　　},
　　　　methods:{
   async roadMap() {
     const {data:res}= await this.$http.post("smtz");
    this.data=res.data
      tiandi.init().then(
        T => {
          this.T = T;
          const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=b02dec10c18d1827f87ef79acf6cd3c0";
          const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
          const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
          this.map = new T.Map('mapDiv');
          
          
          const ctrl = new T.Control.MapType();
          this.map.addControl(ctrl);
          this.map.centerAndZoom(new T.LngLat(116.40969, 37.43997405227057), 5);
          // this.map.setStyle('indigo');
          var arrayObj = new Array();
          for(var i=0;i<this.data.length;i++){
            var marker = new T.Marker(new T.LngLat(this.data[i].lng,this.data[i].lat),{title: i});
            
            arrayObj.push(marker)
          }
          console.log(marker)
          var markers = new T.MarkerClusterer(this.map, {markers: arrayObj});
        }).catch()
        
    },

        getHeight(){
          this.conheight.height=window.innerHeight+'px';
       },
        full(){

        },
        isScreenFull() {
      // 需要注意的是 如果判断!screenfull.enabled 显示的是不支持全屏的话 是因为谷歌的版本问题  判断改为 !screenfull.isEnabled  就可以了

      if (!screenfull.isEnabled) {
        // 如果不支持进入全屏，发出不支持提示
        this.$message({
          message: "您的浏览器版本过低不支持全屏显示！",
          type: "warning"
        });
        return false;
      }
      screenfull.toggle(this.$refs.mapDiv);
    }
},
　　　　created(){
        window.addEventListener('resize', this.getHeight);
        this.getHeight()
        this.roadMap()
     },
}
</script>

<style lang="less" scoped>
 .main{
  overflow: hidden;
} 
.full{
    position: fixed;
    z-index: 1000;
}
.title{
  position: fixed;
    z-index: 1000;
    color: #1890ff;
    text-align: center;
    width: 100%;
    font-size: 28px;
    /* background-color: #000d4a; */
    
}
 #mapDiv {
  width: 90%;
  
  position: absolute;
  // background-color: rgb(70, 99, 153);
}

</style>